import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { memo } from 'react';

import type {
  CategoryRootPageProps
} from 'types/oneflare.com.au/categoryRoot';

import { getCategoryRootPageContent } from './utils/getCategoryRootPageContent';
import {
  usePageTracking
} from './utils/hooks';

// #region lazy loaded components
const CategoryLayout = dynamic(() => import('./components/CategoryLayout'));
const CategoryDefault = dynamic(() => import('./components/CategoryDefault'));
const CategoryEntry = dynamic(() => import('./components/CategoryEntry'));
// #endregion lazy loaded components

const CategoryInner = ({
  innerProps, isEntryPage
}) => {
  if (isEntryPage) return <CategoryEntry {...innerProps} />;
  return <CategoryDefault {...innerProps} />;
};

const Category = (props: CategoryRootPageProps) => {
  const {
    categoryRoot: categoryRootData,
    categoryJobs,
    categoryAverageRating,
    entryPointPage,
    isEntryPage
  } = props;
  const { pathname, query } = useRouter();
  const pageTracking = usePageTracking(categoryRootData);
  const {
    breadCrumbsProps,
    businessesPaginationProps,
    headContentProps,
    headerProps,
    heroProps,
    mainCityProps,
    params,
    renderChoiceTipsProps
  } = getCategoryRootPageContent(props, { pathname, query }, pageTracking);

  const categoryRootInnerContentProps = {
    isEntryPage,
    entryPointPage,
    categoryRootData,
    categoryAverageRating,
    params
  };

  return (
    <>
      <CategoryLayout
        {...{
          headContentProps,
          headerProps,
          heroProps,
          mainCityProps,
          breadCrumbsProps,
          businessesPaginationProps,
          params,
          isEntryPage,
          entryPointPage,
          renderChoiceTipsProps,
          categoryRootData,
          categoryJobs
        }}
      >
        <CategoryInner innerProps={categoryRootInnerContentProps} isEntryPage={isEntryPage} />
      </CategoryLayout>
    </>
  );
};

export default memo(Category);
